<!-- src/components/InternetStatus.svelte -->

<script>
	import { onMount } from 'svelte';
	import Modal from './Modal.svelte';

	/*
	import { onDestroy } from 'svelte';

	let isOnline = navigator.onLine;

	const beforeUnload = () => {
		if (!navigator.onLine) {
			// Display a dialog or take any other action before the page is unloaded
			alert('You are currently offline. Please check your internet connection before leaving.');
		}
	};

	// Add event listener for online/offline changes
	const updateStatus = () => {
		isOnline = navigator.onLine;
	};

	window.addEventListener('online', updateStatus);
	window.addEventListener('offline', updateStatus);

	onBeforeUnmount(() => {
		// Remove event listener on component unmount
		window.removeEventListener('online', updateStatus);
		window.removeEventListener('offline', updateStatus);
	});

	onDestroy(() => {
		// Remove beforeunload listener on component destruction
		window.removeEventListener('beforeunload', beforeUnload);
	});
	*/

	let isOnline = true;

	onMount(() => {
		if(window?.navigator) {
			isOnline = window.navigator?.onLine;
		}
		/*
		window.addEventListener('online', () => {
			// isOnline = true;
		});
		*/
		window.addEventListener('offline', () => {
			isOnline = false;
		});
	});
</script>

{#if !isOnline}
	<Modal title="Your're Offline">
		<div style="padding: 25px;">
			It seems you are offline. Please go back online and refresh this app.
		</div>
	</Modal>
{/if}
