<script lang="ts">
	import { page } from '$app/stores';
	import ViewDashboardIcon from 'svelte-material-icons/ViewDashboard.svelte';
	import AccountGroupIcon from 'svelte-material-icons/AccountGroup.svelte';
	import CalendarMonthIcon from 'svelte-material-icons/CalendarMonthOutline.svelte';
	import ChartBoxIcon from 'svelte-material-icons/ChartBox.svelte';
	import { userProfile } from '$lib/userProfileStore';

	const menu = [
		{ label: 'Schedules', link: '/schedules', icon: CalendarMonthIcon, isCoach: true },
	];

	/*

		{ label: 'Athletes', link: '/athletes', icon: AccountGroupIcon, isCoach: true }
	*/

	let menuItems = getMenuItems({ userType: $userProfile?.type });
	$: {
		menuItems = getMenuItems({ userType: $userProfile?.type });
	}

	function getMenuItems({ userType }) {
		return menu.filter((item) => {
			return item.isCoach == (userType === 1 ? true : false);
		});
	}
</script>

<aside>
	<div style="flex: 1;">
		<a href="/">
			<div id="logo">SCULPT X</div>
		</a>
		<ul>
			{#each menuItems as item (item.label)}
				<li>
					<a href={item.link} class:active={item.link === $page.url.pathname}>
						{#if item.icon}
							<svelte:component this={item.icon} size="20" />
						{/if}
						<span>
							{item.label}
						</span>
					</a>
				</li>
			{/each}
		</ul>
	</div>
	<a href="/profile" style="color:inherit;">
		<div class="account-profile">
			<div class="avatar">
				{$userProfile?.fName?.[0]?.toUpperCase() || ''}{$userProfile?.lName?.[0]?.toUpperCase() ||
					''}
			</div>
			<div class="account-details">
				<div style="font-size: 14px;">{$userProfile?.fName || ''} {$userProfile?.lName || ''}</div>
				{#if $userProfile?.email}
					<div style="color: gray; font-size: 11px;">
						{$userProfile?.email || ''}
					</div>
				{/if}
			</div>
		</div>
	</a>
</aside>

<style lang="scss">
	#logo {
		font-family: 'Dela Gothic One', sans-serif;
		height: 70px;
		font-size: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--color-primary-light);
		border-bottom: 1px solid #222;
		margin-bottom: 30px;
	}

	aside {
		width: 240px;
		background-color: #0a0a0a;
		position: fixed;
		height: 100%;
		display: flex;
		flex-direction: column;
		color: white;

		ul {
			display: flex;
			flex-direction: column;
			gap: 10px;

			a {
				display: flex;
				padding: 10px 15px;
				color: white;
				transition: background 0.3s ease;
				text-decoration: none;
				margin: 0 20px;
				border-radius: 8px;
				align-items: center;
				gap: 10px;

				&:hover,
				&.active {
					background-color: #222222;
				}
			}
		}
	}

	@media screen and (max-width: 1600px) {
		aside {
			width: 60px;

			li {
				a {
					margin: 0 5px;
					padding: 15x 10px;
				}

				span {
					display: none;
				}
			}
		}

		#logo {
			font-size: 4px;
		}

		.account-details {
			display: none;
		}
	}

	.account-profile {
		height: 70px;
		background: #222222;
		display: flex;
		padding: 10px;
		display: flex;
		gap: 10px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		align-items: center;
	}

	.avatar {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		font-size: 12px;
		background-color: #fff;
		color: black;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
