<script>
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
</script>

<div
	style="display: flex; align-items:center;justify-content:center;width:100vw;height:100vh;flex-direction: column;">
	<div>
		<SavingIcon />
	</div>
	<div style="font-size: 28px;font-family: 'Dela Gothic One', sans-serif;margin-top: 20px;">
		SCULPT X
	</div>
</div>
