<script>
	import { user } from '$lib/authStore';
	import { userProfile } from '$lib/userProfileStore';
	import './reset.css';
	import './styles.css';
	import { auth } from '$lib/firebase';
	import Login from '$lib/core/Login.svelte';
	import Dashboard from './Dashboard.svelte';
	import { createUser, fetchUser } from '$lib/userAPI';
	import '@fontsource/dela-gothic-one';
	// import '@fontsource-variable/inter';
	import '@fontsource/poppins/400.css';
	import '@fontsource/poppins/500.css';
	import '@fontsource/poppins/600.css';
	import '@fontsource/poppins/700.css';
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
	import OnlineStatus from '$lib/core/OnlineStatus.svelte';
	import { captureException } from '@sentry/sveltekit';
	import Modal from '$lib/core/Modal.svelte';
	import LoadingContentBody from '$lib/LoadingContentBody.svelte';
	import { page } from '$app/stores';
	import { onMount } from 'svelte';
	import ClientSignUp from '$lib/pages/ClientSignUpPage.svelte';
	import AppLoading from '$lib/AppLoading.svelte';
	import OnboardingPage from '$lib/pages/OnboardingPage.svelte';

	let isLoading = true;
	let dialogKey = '';

	let showOnboarding = false;

	let isAthleteSignup = $page.route.id === '/athlete-signup';
	$: {
		isAthleteSignup = $page.route.id === '/athlete-signup';
	}

	auth.onAuthStateChanged(async (authUser) => {
		try {
			isLoading = true;

			if (authUser?.uid) {
				user.set(authUser);

				let _userProfile = await fetchUser(authUser?.uid);

				userProfile.set(_userProfile);

				isLoading = false;
			} else {
				user.set(null);
				userProfile.set(null);
				isLoading = false;
			}
		} catch (e) {
			dialogKey = 'error';
			captureException(e);
		}
	});
</script>

<div class="app">
	<main>
		{#if isLoading}
			<AppLoading />
		{:else if $user?.uid}
			{#if $userProfile?.id}
				<Dashboard>
					<slot />
				</Dashboard>
			{:else}
				<OnboardingPage />
			{/if}
		{:else if isAthleteSignup}
			<ClientSignUp />
		{:else}
			<Login />
		{/if}
	</main>

	<OnlineStatus />
</div>

{#if dialogKey == 'error'}
	<Modal title="Ooops">
		<div style="padding: 25px;">We are unable to log you in. Please try again later.</div>
	</Modal>
{/if}
