<script>
	import Button from '$lib/Button.svelte';
	import Container from '$lib/Container.svelte';
	import NotFoundPage from '$lib/core/NotFoundPage.svelte';
	import SavingOverlay from '$lib/core/SavingOverlay.svelte';
	import SocialButton from '$lib/core/SocialButton.svelte';
	import { createUserWithEmail, signInWithGoogle } from '$lib/firebase';
	import Form from '$lib/form/Form.svelte';
	import FormLabel from '$lib/form/FormLabel.svelte';
	import TextInput from '$lib/form/TextInput.svelte';
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
	import reportValidity from '$lib/utils/reportValidity';
	import { auth } from '$lib/firebase';
	import { onMount } from 'svelte';
	import { createUser, fetchUser } from '$lib/userAPI';
	import { captureException } from '@sentry/sveltekit';
	import { createUserWithEmailAndPassword } from 'firebase/auth';
	import { page } from '$app/stores';
	import Crypto, { enc } from 'crypto-js';
	import { env } from '$env/dynamic/public';
	import ConfirmModal from '$lib/core/ConfirmModal.svelte';
	import MessageModal from '$lib/core/MessageModal.svelte';
	import { userProfile } from '$lib/userProfileStore';
	import { goto } from '$app/navigation';

	let isNotFound = false;
	let isLoading = true;

	let trainerId = '';
	let trainer;
	let fName = '';
	let lName = '';
	let email = '';
	let password = '';

	onMount(async () => {
		let trainerId = String($page.url.searchParams.get('t') || '');

		if (!trainerId) {
			isNotFound = true;
			return;
		}

		try {
			trainerId = decodeURIComponent(
				Crypto?.AES.decrypt(trainerId, env.PUBLIC_ADD_ATHLETE_PUBLIC_KEY).toString(enc.Utf8)
			);

			if (!trainerId) {
				isNotFound = true;
			}
		} catch (e) {
			console.log(e);
			isNotFound = true;
			return;
		}

		try {
			trainer = await fetchUser(trainerId);

			if (trainer) {
			} else {
				isNotFound = true;
				return;
			}
		} catch (e) {}

		isLoading = false;
	});

	let error = '';
	let isCreating = false;

	
	async function handleCreateUserProfile(authUser) {
		if (!$userProfile?.id) {
			try {
				let user = await createUser(authUser?.uid, {
					fName,
					lName,
					trainerId: trainer?.id
				});

				userProfile.set(user);

				goto('/');
			} catch (e) {
				console.log(e);
				captureException(e);
			}
		}
	}

	async function handleEmailSignUp(event) {
		try {
			event.preventDefault();

			email = String(email || '').trim();
			password = String(password || '').trim();
			fName = String(fName || '').trim();
			lName = String(lName || '').trim();

			if (reportValidity('#sign-up-form')) {
				isCreating = true;

				let authUser = await createUserWithEmail(email, password);
				await handleCreateUserProfile(authUser);
			}
		} catch (e) {
			if (e?.code === 'auth/email-already-in-use') {
				error = 'account-exist';
			}

			captureException(e);

			isCreating = false;

			// throw error message
		}
	}

	async function handleGoogleSignIn() {
		// todo = save the user into database.

		// isCreating = true;

		try {
			let authUser = await signInWithGoogle();
			console.log("signed in", authUser)
		} catch (e) {}

		// isCreating = false;
		return;

		if (authUser) {
			let user = await fetchUser(authUser?.uid);

			console.log(user);

			if (user) {
				if (!user.trainerId) {
					// add to the athlete
				} else if (user.trainerId === trainerId) {
					// already registed to the user
				} else {
				}

				// already has the user
			} else {
				/*
				user = await createUser({
					fName,
					lName,
					email,
					trainerId
				});
				*/
			}

			console.log('create the user');
			console.log(user);
		}

		isCreating = false;
	}
</script>

<svelte:head>
	<title>Sculpt X | Create Athlete Account</title>
</svelte:head>

{#if isNotFound}
	<NotFoundPage />
{:else if isLoading}
	<div style="margin-top:100px;">
		<SavingIcon />
	</div>
{:else}
	<Container>
		<div style="text-align:center;width:400px;margin: 50px auto;">
			<div style="font-weight:500;font-size:22px;margin-bottom:40px;">Sculpt X</div>

			<div style="margin-bottom:50px;">
				<div class="color-muted">Create your account to train with</div>
				<div style="color: var(--color-primary);font-weight:600;font-size:24px;">
					{trainer?.fName}
					{trainer?.lName}
				</div>
			</div>

			<div class="flex justify-center" style="margin-bottom:30px;">
				<SocialButton isSignIn={false} onClick={handleGoogleSignIn} />
			</div>

			<div
				class="color-muted"
				style="margin-bottom:30px;font-weight:500;text-transform:uppercase;font-size:12px;"
			>
				or sign up with email
			</div>

			<Form id="sign-up-form" style="text-align:left;" onSubmit={handleEmailSignUp}>
				<div>
					<FormLabel>First Name</FormLabel>
					<TextInput
						placeholder="First Name"
						value={fName}
						required
						maxLength={100}
						onChange={(event) => {
							fName = event.target.value;
						}}
					/>
				</div>
				<div>
					<FormLabel>Last Name</FormLabel>
					<TextInput
						placeholder="Last Name"
						value={lName}
						required
						maxLength={100}
						onChange={(event) => {
							lName = event.target.value;
						}}
					/>
				</div>
				<div>
					<FormLabel>Email</FormLabel>
					<TextInput
						type="email"
						placeholder="Enter email"
						value={email}
						required
						minLength={5}
						maxLength={100}
						onChange={(event) => {
							email = event.target.value;
						}}
					/>
				</div>
				<div>
					<FormLabel>Password</FormLabel>
					<TextInput
						type="password"
						placeholder="Enter password"
						value={password}
						required
						minLength={5}
						maxLength={100}
						onChange={(event) => {
							password = event.target.value;
						}}
					/>
				</div>
				<Button color="primary" style="justify-content:center;">Sign Up</Button>
			</Form>

			<div style="margin-top: 50px;" class="color-muted">
				<div>2024 SculptX</div>
			</div>
		</div>
	</Container>
{/if}

{#if isCreating}
	<SavingOverlay />
{/if}

{#if error == 'account-exist'}
	<MessageModal
		title="Oops"
		onClose={() => {
			error = '';
		}}
	>
		<div>
			This email already exists. <a href="/">Click here to login.</a>
		</div>
	</MessageModal>
{/if}
