<script>
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
</script>

<div class="loading">
	<SavingIcon />
</div>

<style>
	.loading {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
	}
</style>
