<script lang="ts">
	import LoadingContentBody from '$lib/LoadingContentBody.svelte';
	import { user } from '$lib/authStore';
	import { userProfile } from '$lib/userProfileStore';
	import Chat from './Chat.svelte';
	import NotificationWrapper from './NotificationWrapper.svelte';
	import Sidebar from './Sidebar.svelte';
</script>

<div id="dashboard">
	<Sidebar />
	<div id="content">
		{#if $userProfile?.id}
			<slot />
		{:else}
			<LoadingContentBody />
		{/if}
	</div> 

	<NotificationWrapper />
</div>

<style>
	#dashboard {
		gap: 20px;
		flex: 1;
		display: flex;
		width: 100%;

		/*
		flex-direction: column;
		*/
	}

	#content {
		flex: 1;
		margin-left: 240px;
		width: 1%;
		/*
		width: 100% !important;
		margin-left: 0 !important;
		margin-top: 55px;
		*/
	}

	@media screen and (max-width: 1600px) {
		#content {
			margin-left: 60px;
		}
	}
</style>
