<script>
	/*
		- ask for name
		- ask for schedule
	*/

	import Button from '$lib/Button.svelte';
	import Container from '$lib/Container.svelte';
	import NotFoundPage from '$lib/core/NotFoundPage.svelte';
	import SavingOverlay from '$lib/core/SavingOverlay.svelte';
	import SocialButton from '$lib/core/SocialButton.svelte';
	import { createUserWithEmail, signInWithGoogle } from '$lib/firebase';
	import Form from '$lib/form/Form.svelte';
	import FormLabel from '$lib/form/FormLabel.svelte';
	import TextInput from '$lib/form/TextInput.svelte';
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
	import reportValidity from '$lib/utils/reportValidity';
	import { auth } from '$lib/firebase';
	import { onMount } from 'svelte';
	import { createUser, fetchUser } from '$lib/userAPI';
	import { captureException } from '@sentry/sveltekit';
	import { createUserWithEmailAndPassword } from 'firebase/auth';
	import { page } from '$app/stores';
	import Crypto, { enc } from 'crypto-js';
	import { env } from '$env/dynamic/public';
	import ConfirmModal from '$lib/core/ConfirmModal.svelte';
	import MessageModal from '$lib/core/MessageModal.svelte';
	import { userProfile } from '$lib/userProfileStore';
	import { user } from '$lib/authStore';

	let isNotFound = false;
	let isLoading = true;

	let trainerId = '';
	let trainer;
	let fName = '';
	let lName = '';
	let email = '';
	let password = '';

	async function handleCreateUser() {
		/*
		let trainerId = '';

		let response = await createUser($user?.uid, {
			fName: '',
			lName: '',
			trainerId: ''
		});

		userProfile.set(response);
		*/
	}

	onMount(async () => {
		isLoading = false;

		console.log(userProfile);

		return;

		let trainerId = String($page.url.searchParams.get('t') || '');

		if (!trainerId) {
			isNotFound = true;
			return;
		}

		try {
			trainerId = decodeURIComponent(
				Crypto?.AES.decrypt(trainerId, env.PUBLIC_ADD_ATHLETE_PUBLIC_KEY).toString(enc.Utf8)
			);

			if (!trainerId) {
				isNotFound = true;
			}
		} catch (e) {
			console.log(e);
			isNotFound = true;
			return;
		}

		try {
			trainer = await fetchUser(trainerId);

			if (trainer) {
			} else {
				isNotFound = true;
				return;
			}
		} catch (e) {}

		isLoading = false;
	});

	let error = '';
	let isCreating = false;

	async function handleSignup(event) {
		try {
			event.preventDefault();

			fName = String(fName || '').trim();
			lName = String(lName || '').trim();

			if (reportValidity('#sign-up-form')) {
				isCreating = true;

				let responseUserProfile = await createUser($user?.uid, {
					fName,
					lName
				});

				userProfile.set(responseUserProfile);
			}
		} catch (e) {
			captureException(e);
		}
	}
</script>

<svelte:head>
	<title>Sculpt X | Create Athlete Account</title>
</svelte:head>

{#if isNotFound}
	<NotFoundPage />
{:else if isLoading}
	<div style="margin-top:100px;">
		<SavingIcon />
	</div>
{:else}
	<Container>
		<div style="text-align:center;width:400px;margin: 50px auto;">
			<div style="font-weight:500;font-size:22px;margin-bottom:40px;">Sculpt X</div>

			<Form id="sign-up-form" style="text-align:left;" onSubmit={handleSignup}>
				<div>
					<FormLabel>First Name</FormLabel>
					<TextInput
						placeholder="First Name"
						value={fName}
						required
						maxLength={100}
						onChange={(event) => {
							fName = event.target.value;
						}}
					/>
				</div>
				<div>
					<FormLabel>Last Name</FormLabel>
					<TextInput
						placeholder="Last Name"
						value={lName}
						required
						maxLength={100}
						onChange={(event) => {
							lName = event.target.value;
						}}
					/>
				</div>
				<Button color="primary" style="justify-content:center;">Create Account</Button>
			</Form>

			<div style="margin-top: 50px;" class="color-muted">
				<div>2024 SculptX</div>
			</div>
		</div>
	</Container>
{/if}

{#if isCreating}
	<SavingOverlay />
{/if}

{#if error == 'account-exist'}
	<MessageModal
		title="Oops"
		onClose={() => {
			error = '';
		}}
	>
		<div>
			This email already exists. <a href="/">Click here to login.</a>
		</div>
	</MessageModal>
{/if}
