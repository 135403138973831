<script>
	import { app } from '$lib/firebase';
	import { notifications } from '$lib/stores/notificationStore';
	import { updateUser } from '$lib/userAPI';
	import { userProfile } from '$lib/userProfileStore';
	import { captureException } from '@sentry/sveltekit';
	import { getToken, onMessage, getMessaging } from 'firebase/messaging';
	import { onMount } from 'svelte';

	onMount(() => {
		try {
			Notification.requestPermission()
				.then((permission) => {
					if (permission === 'granted') {
						let vapidKey = 'BPyfNlzwPfBcT8HVFq0GBUprgCrfqWdPL2emNAzNRGkrzoWzvt8wiIe_JPw_JFYEbsQt8jnHgOiIkTxp2hTcO7U';

						const messaging = getMessaging(app);
						getToken(messaging, {
							vapidKey
						})
							.then(async (token) => {
								if (token) {
									try {
										let fcmTokens = $userProfile?.fcm || [];

										if (!fcmTokens.includes(token)) {
											// save the token the user profile
											fcmTokens = [
												...fcmTokens,
												token
											]

											await updateUser({
												id: $userProfile?.id,
												payload: {
													fcm: fcmTokens
												}
											})
										}
									} catch (e) {
										captureException(e);
									}

									onMessage(messaging, (payload) => {
										notifications.update(value => {
											return [
												...value,
												{
													...(payload?.data || {}),
													created_at: new Date().toISOString(),
													isRead: false,
												}
											]
										})
									});
								}

							})
							.catch((e) => {
								console.log('error', e);
							});
					}
				})
				.catch((error) => {
					console.log('error', error);
				});
		} catch (e) {
			console.log('Messaging is offline');
		}
	});
</script>