<script lang="ts">
	import { signInWithEmail, signInWithGoogle } from '$lib/firebase';
	import { user } from '$lib/authStore';
	import TextInput from '$lib/form/TextInput.svelte';
	import SocialButton from './SocialButton.svelte';
	import Button from '$lib/Button.svelte';
	import Form from '$lib/form/Form.svelte';
	import reportValidity from '$lib/utils/reportValidity';

	let email = '';
	let password = '';
	let error = '';

	async function handleEmailSignIn() {
		if (reportValidity('#login-form')) {
			error = '';

			email = String(email || '').trim();
			password = String(password || '').trim();

			if (!email) {
				error = 'Please enter valid email.';
			} else if (!password) {
				error = 'Please enter a password.';
			} else if (password.length < 5) {
				error = 'Please enter a password.';
			}

			if (!error) {
				try {
					await signInWithEmail(email, password);
				} catch(e) {
					error = "Invalid email or password";
				}
			}
		}
	}

	async function handleGoogleSignIn() {
		await signInWithGoogle();
	}
</script>

<svelte:head>
	<title>Sculpt X | Personal Trainer App</title>
</svelte:head>

<div class="full-container">
	<div class="left">
		<div>
			<div style="color: white;font-size:54px;font-weight:900;">Sculpt X</div>
			<div style="color: white;margin-top:20px;font-size:20px;" />
		</div>
	</div>
	<div class="right">
		<section>
			<div style="text-align:center;">
				<div style="font-size: 22px;font-weight:700;">Sign In</div>
				<div style="margin-top:10px;color: rgb(153, 161, 183);font-size:14px;font-weight:500;">
					For Fitness Coaches
				</div>
			</div>

			<div style="margin: 40px 0;display: flex;">
				<SocialButton onClick={handleGoogleSignIn} />
			</div>

			<div class="separator" />

			<Form id="login-form">
				<TextInput
					type="email"
					placeholder="Email"
					value={email}
					required
					maxLength={100}
					onChange={(event) => {
						email = event.target.value;
					}}
				/>

				<div>
					<TextInput
						type="password"
						placeholder="Password"
						value={password}
						required
						minLength={5}
						maxLength={100}
						onChange={(event) => (password = event.target.value)}
					/>

					<div style="text-align:right;display:none;">
						<a
							href="#"
							style="font-size:12px;text-decoration:none;margin-top:5px;display:inline-block;color:inherit;"
							>Forget password</a
						>
					</div>
				</div>

				{#if error}
					<div style="color: red;">{error}</div>
				{/if}

				<Button style="justify-content:center;" onClick={handleEmailSignIn}>Sign In</Button>
			</Form>

			<!---
			<div style="font-weight:500;text-align:center;margin-top: 40px;">
				Not a Member yet? <a href="#">Sign up</a>
			</div>
			--->
		</section>
	</div>
</div>

<div class="background" />

<style lang="scss">
	/*
	:global(body) {
		background-attachment: fixed;
		background-size: cover;
		background-image: url('/login_background.jpg');
		background-position: center;
		background-repeat: no-repeat;
	}

	:global(body:after) {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: -1;
	}
	*/

	.full-container {
		display: flex;
		justify-content: center;
		width: 100vw;
		overflow-x: hidden;
		height: 100vh;
	}

	.background {
		background-attachment: fixed;
		background-size: cover;
		background-image: url(/login_background.jpg);
		background-position: center;
		background-repeat: no-repeat;
		position: fixed;
		background-color: #333;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}

	.background:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: -1;
	}

	h2 {
		font-size: 28px;
		line-height: 1.4;
		margin-bottom: 32px;
	}

	section {
		padding: 65px;
		background-color: white;
		width: 600px;
		border-radius: 8px;
	}

	form {
		display: flex;
		flex-direction: column;
		gap: 25px;
	}

	.left {
		width: 50%;
		padding: 65px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.right {
		width: 50%;
		padding: 65px;
		display: flex;
		align-items: center;
	}

	.separator {
		border-top: 1px solid var(--color-border);
		margin: 45px 0;
		position: relative;

		&:after {
			content: 'Or with email';
			color: rgb(153, 161, 183);
			font-size: 13px;
			font-weight: 500;
			background: white;
			top: -8px;
			padding: 0 10px;
			left: calc(50% - 50px);
			position: absolute;
		}
	}
</style>
